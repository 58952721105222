/* eslint-disable import/no-anonymous-default-export */
import jasDadSnow from "./jas-dad-snow-dwend.jpeg";
import dadRain from "./dad-rain-cornfield-crop.jpg"


export default () => (
    <>
        <span className="hint" style={{fontSize: "20px"}}>Hint: Tap or mouse over the card.</span>
        <div className="birthdayCard">
            <div className="cardFront">
                <h3 className="happy">HAPPY BIRTHDAY DAD!</h3>
                <div className="balloons">

                    <div className="balloonOne"/>
                    <div className="dadRain"/>
                    <img src={dadRain} alt="Dad Rain" className="dadRain"/>
                    <div className="balloonTwo"/>
                    <div className="balloonThree"/>

                    <div className="balloonFour"/>
                    <div className="balloonFive"/>

                </div>
            </div>
            <div className="cardInside">
                <h3 className="back">I programmed a card for you!<br/><span
                    className="back2">(Cheaper than Hallmark.)</span></h3>
                <p>Hi Dad,</p>
                <p>
                    I spent a long time building this web site just to say I love
                    you and I will always consider myself lucky on a cosmic scale to have
                    you as my father. I don't really have words for how important you are to me, so I'll just wrap it up
                    here.
                </p>
                <img
                    src={jasDadSnow}
                    alt="Jas Dad"
                    className="jasDad"
                    style={{width: "200px", height: "auto", marginLeft: "50px"}}
                />

                <p className="name">Love, <br/>Jas</p>
            </div>
            {/*<div className="hint">*/}
            {/*	<footer>Hint: mouse over the card.</footer>*/}
            {/*</div>*/}
        </div>
    </>
);
